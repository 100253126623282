import { type ProjectMeta } from "@tokenterminal/tt-analytics-api-types/dist/api/projects"
import { atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { fetchAtomWithRefresh } from "../../api/store/fetch-atom"
import { unwrapWithData } from "../../utils/jotai/unwrap"
import { toDictionary } from "../../utils/toDictionary"
import { getMetricsAtom } from "./metrics-atom"

export const getProjectsAtom = fetchAtomWithRefresh("/v3/internal/projects")

export const getChainsAtom = fetchAtomWithRefresh("/v3/internal/chains")

export const getProjectsByMetricAtom = atomFamily((metricId: string) => {
  // temporary to fix slug issues
  const metricsMetaAtom = getMetricsAtom

  const projectsByMetricAtom = atom(async (get) => {
    const projectsMetaPromise = get(getProjectsAtom)
    const metrics = unwrapWithData(await get(metricsMetaAtom))
    const metricConfig = metrics.find((m) => m.metric_id === metricId)

    if (!metricConfig) {
      // eslint-disable-next-line no-console
      console.warn(
        "getProjectsByMetricAtom expects a metricId, but got",
        metricId
      )
    }

    const getProjectSlugsByMetricAtom = fetchAtomWithRefresh(
      // this is to support both slug and id
      `/v3/internal/metrics/${metricConfig?.slug ?? metricId}/project-availability` as "/v3/internal/metrics/:slug/project-availability"
    )

    const [availableProjectsPayload, projectsMeta] = await Promise.all([
      get(getProjectSlugsByMetricAtom),
      projectsMetaPromise,
    ])

    // convert project slugs to full project meta objects
    const projectDictionary = toDictionary(unwrapWithData(projectsMeta), "slug")

    const result: Array<ProjectMeta> = []
    if (availableProjectsPayload) {
      for (const project of unwrapWithData(availableProjectsPayload)) {
        if (projectDictionary.has(project.slug)) {
          result.push(projectDictionary.get(project.slug)!)
        }
      }
    }

    return result
  })

  return projectsByMetricAtom
})

export const getProjectsByMarketSectorAtom = atomFamily(
  (marketSector: string) => {
    const projectsByMarketSector = atom(async (get) => {
      const projectsMeta = unwrapWithData(await get(getProjectsAtom))

      return projectsMeta.filter((project) => {
        return project.flattened_tags.includes(marketSector)
      })
    })

    return projectsByMarketSector
  }
)

export const getProjectsByBlockchainAtom = atomFamily((blockchain: string) => {
  const projectsByBlockchain = atom(async (get) => {
    const projectsMeta = unwrapWithData(await get(getProjectsAtom))

    return projectsMeta.filter((project) => {
      return project.chains.includes(blockchain)
    })
  })

  return projectsByBlockchain
})
