import { useMemo } from 'react'
import { type GranularityType } from '../Chart'
import { chartCSSVars } from '../ChartContract.css'
import type {
  AxisLabelsFormatterCallbackFunction,
  XAxisOptions,
} from 'highcharts'

const DEFAULT_X_AXIS_OPTIONS: XAxisOptions = {
  type: 'category',
  labels: {
    rotation: -45,
  },
}

const DEFAULT_TIMEBASED_X_AXIS_OPTIONS: XAxisOptions = {
  type: 'datetime',
  dateTimeLabelFormats: {
    day: '%m/%d/%y',
    week: '%m/%d/%y',
    month: "%b '%y",
  },
  units: [
    [
      'millisecond', // unit name
      [1, 2, 5, 10, 20, 25, 50, 100, 200, 500], // allowed multiples
    ],
    ['second', [1, 2, 5, 10, 15, 30]],
    ['minute', [1, 2, 5, 10, 15, 30]],
    ['hour', [1, 2, 3, 4, 6, 8, 12]],
    ['day', [1, 2, 3, 4]],
    ['week', [1, 2]],
    ['month', [1, 2, 3, 4, 6]],
    ['year', null],
  ],
}

const axisFormatter: AxisLabelsFormatterCallbackFunction =
  function axisFormatter() {
    switch (this.axis.series[0].options.custom!.granularity) {
      case 'quarter': {
        const parsedDate = new Date(this.value)
        const quarter = Math.floor((parsedDate.getUTCMonth() + 3) / 3)

        return `Q${quarter} '${parsedDate.getUTCFullYear().toString().substring(2)}`
      }
      case 'month': {
        return this.axis.chart.time.dateFormat(`%b '%y`, this.value as number)
      }
      case 'week': {
        return this.axis.chart.time.dateFormat('%m/%d/%y', this.value as number)
      }
      default: {
        return this.axis.chart.time.dateFormat(
          this.dateTimeLabelFormat!,
          this.value as number,
        )
      }
    }
  }

export function useXAxisOptions(
  isTimeBased: boolean,
  granularity: GranularityType,
  xAxisOptions?: XAxisOptions,
): XAxisOptions {
  return useMemo<XAxisOptions>(() => {
    const timeBasedOptions = isTimeBased
      ? DEFAULT_TIMEBASED_X_AXIS_OPTIONS
      : DEFAULT_X_AXIS_OPTIONS

    const xAxis: Partial<XAxisOptions> = {
      id: 'main-xaxis',
      overscroll: 0,
      gridLineColor: chartCSSVars.axis.gridColor,
      lineColor: chartCSSVars.axis.gridColor,
      tickColor: chartCSSVars.axis.tickColor,
      ...timeBasedOptions,
      ...xAxisOptions,
      // tickPositioner: isTimeBased
      //   ? function () {
      //       if (!this.min || !this.max || !this.tickPositions?.length) {
      //         return this.tickPositions ?? []
      //       }

      //       let tickPositions = this.tickPositions
      //       const amountOfTicks = this.series.reduce((acc, serie) => {
      //         return Math.max(acc, serie.processedXData.length)
      //       }, 0)

      //       if (amountOfTicks <= tickPositions.length) {
      //         tickPositions =
      //           this.series.find(
      //             (serie) => serie.processedXData.length === amountOfTicks,
      //           )?.processedXData ?? this.tickPositions
      //       }

      //       const min = this.min!
      //       const max = this.max!
      //       return Array.from(
      //         new Set(
      //           [this.min, ...tickPositions, this.max].filter((x) => {
      //             if (x < min || x > max) {
      //               return false
      //             }

      //             return !!x
      //           }),
      //         ),
      //       )
      //     }
      //   : undefined,
      showFirstLabel: true,
      showLastLabel: true,
      labels: {
        overflow: 'justify',
        formatter: isTimeBased ? axisFormatter : undefined,
        ...timeBasedOptions.labels,
        ...xAxisOptions?.labels,
        style: {
          fontSize: chartCSSVars.axis.fontSize,
          color: chartCSSVars.axis.color,
          fontWeight: '500',
          ...timeBasedOptions.labels?.style,
          ...xAxisOptions?.labels?.style,
        },
        distance: 0,
      },

      // events: {
      //   // @ts-ignore
      //   afterRender: isTimeBased
      //     ? function (this: Highcharts.Axis) {
      //         function getLabelDimensions(label: SVGTextElement) {
      //           const width = label.getBoundingClientRect().width
      //           const offset = Number(label.getAttribute('x'))

      //           return {
      //             width,
      //             offset,
      //           }
      //         }

      //         // @ts-ignore - it exists
      //         this.adjustTickAmount()

      //         // figure out if any labels overlap
      //         const xAxisEl = this.chart.container.querySelector(
      //           '.highcharts-xaxis-labels',
      //         )
      //         if (xAxisEl) {
      //           const labels = Array.from(
      //             xAxisEl.children as HTMLCollectionOf<SVGTextElement>,
      //           ).sort((a, b) => {
      //             return (
      //               Number(a.getAttribute('x')) - Number(b.getAttribute('x'))
      //             )
      //           })
      //           // cleanup labels
      //           if (labels.length) {
      //             const firstLabel = labels[0]
      //             const firstLabelDimensions = getLabelDimensions(firstLabel)
      //             const lastLabel = labels[labels.length - 1]
      //             const lastLabelDimensions = getLabelDimensions(lastLabel)

      //             if (firstLabelDimensions.offset < this.chart.plotLeft) {
      //               firstLabel.setAttribute('x', `${this.chart.plotLeft}`)
      //               firstLabelDimensions.offset = this.chart.plotLeft
      //             }

      //             if (
      //               lastLabelDimensions.offset + lastLabelDimensions.width >
      //               this.chart.plotWidth
      //             ) {
      //               const offset =
      //                 this.chart.plotLeft +
      //                 this.chart.plotWidth -
      //                 lastLabelDimensions.width / 2
      //               lastLabel.setAttribute('x', String(offset))
      //               lastLabelDimensions.offset = offset
      //             }

      //             for (let i = 1; i < labels.length - 1; i++) {
      //               const currentLabel = labels[i]
      //               const dimensions = getLabelDimensions(currentLabel)
      //               let shouldBeHidden = false

      //               if (
      //                 dimensions.offset <
      //                 firstLabelDimensions.offset + firstLabelDimensions.width
      //               ) {
      //                 shouldBeHidden = true
      //               }

      //               if (
      //                 dimensions.offset + dimensions.width >
      //                 lastLabelDimensions.offset
      //               ) {
      //                 shouldBeHidden = true
      //               }

      //               if (shouldBeHidden) {
      //                 currentLabel.style.visibility = 'hidden'
      //               } else {
      //                 currentLabel.style.visibility = 'visible'
      //               }
      //             }
      //           }
      //         }
      //       }
      //     : undefined,
      // },
    }

    return xAxis
  }, [isTimeBased, xAxisOptions])
}
