import { type CustomChart } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { Box } from "@tokenterminal/ui/Box"
import { type GranularityType } from "@tokenterminal/ui/Chart/Chart"
import { Stack } from "@tokenterminal/ui/Stack"
import { type Atom, atom } from "jotai"
import { Suspense, useMemo } from "react"
import { focusAtom } from "../../utils/jotai/focusAtom"
import { BaseChart } from "./components/chart/BaseChart"
import { ChartHeading } from "./components/chart/ChartHeading"
import { Legend } from "./components/legend/Legend"
import { LegendPlaceholder } from "./components/legend/Placeholder"

export type ChartProps = {
  configAtom: Atom<CustomChart>
  showTitle: boolean
  showDescription: boolean
  isVisible: boolean
  onVisibilityChange?: (id: string, isVisible: boolean) => void
}

export function Chart({
  configAtom,
  showTitle,
  showDescription,
  isVisible = true,
  onVisibilityChange,
}: ChartProps) {
  const [
    chartSerieSettingsAtom,
    titleAtom,
    descriptionAtom,
    zoomAtom,
    granularityAtom,
    showCurrentPeriodAtom,
  ] = useMemo(() => {
    const derivedShowCurrentPeriodAtom: Atom<boolean> = atom((get) => {
      const res = get(configAtom)
      return res.show_current_period ?? false
    })

    return [
      focusAtom(configAtom, "configs"),
      focusAtom(configAtom, "title"),
      focusAtom(configAtom, "description"),
      focusAtom(configAtom, "zoom"),
      focusAtom(configAtom, "granularity") as Atom<GranularityType>,
      derivedShowCurrentPeriodAtom,
    ]
  }, [configAtom])

  return (
    <Stack gap="3x" height="100%">
      <Stack gap={showDescription || showTitle ? "3x" : "0"}>
        <ChartHeading
          showTitle={showTitle}
          showDescription={showDescription}
          titleAtom={titleAtom}
          descriptionAtom={descriptionAtom}
        />
        <Suspense fallback={<LegendPlaceholder />}>
          <Legend
            onVisibilityChange={onVisibilityChange}
            serieSettingsAtom={chartSerieSettingsAtom}
            zoomAtom={zoomAtom}
          />
        </Suspense>
      </Stack>
      <Box position="relative" minHeight={0} flexGrow={1}>
        <BaseChart
          isVisible={isVisible}
          chartSerieSettingsAtom={chartSerieSettingsAtom}
          zoomAtom={zoomAtom}
          granularityAtom={granularityAtom}
          showCurrentPeriodAtom={showCurrentPeriodAtom}
        />
      </Box>
    </Stack>
  )
}
