import { atom, type Atom } from "jotai"

export function focusAtom<Value, Prop extends keyof Value>(
  baseAtom: Atom<Promisable<Value>>,
  propertyName: Prop
): typeof baseAtom extends Atom<Promise<Value>>
  ? Atom<Promise<Value[Prop]>>
  : Atom<Value[Prop]>
export function focusAtom<Value, Prop extends keyof Value>(
  baseAtom: Atom<Promise<Value>>,
  propertyName: Prop
): Atom<Promise<Awaited<Value>[Prop]>>
export function focusAtom<Value, Prop extends keyof Value>(
  baseAtom: Atom<Value>,
  propertyName: Prop
): Atom<Value[Prop]>
export function focusAtom<Value, Prop extends keyof Value>(
  baseAtom: Atom<Value | Promise<Value> | Promisable<Value>>,
  propertyName: Prop
): typeof baseAtom extends Atom<Promise<Value>>
  ? Atom<Promise<Value>>
  : Atom<Value> {
  const focusedAtom = atom((get) => {
    const baseValue = get(baseAtom)

    return baseValue instanceof Promise
      ? (baseValue as Promise<Value>).then((value) => value[propertyName])
      : (baseValue as unknown as Value)[propertyName]
  })

  focusedAtom.debugLabel = `focusAtom(${baseAtom.debugLabel}-${propertyName as string})`
  focusedAtom.debugPrivate = true

  return focusedAtom as typeof baseAtom extends Atom<Promise<Value>>
    ? Atom<Promise<Value>>
    : Atom<Value>
}
