import { type IconWeight } from "@phosphor-icons/react"
import { type CSSProperties } from "react"

export type IconProps = {
  /** An element that comes from the `@phosphor-icons/react` package. */
  as: React.ElementType | React.ForwardRefExoticComponent<unknown>
  "aria-label"?: string
  className?: string
  height?: number | string
  role?: string
  style?: CSSProperties
  weight?: IconWeight
  width?: number | string
}

/**
 * A visual representation of an object, concept, or action. Often used to reinforce meaning of other content or buttons.
 */

export function Icon({
  width = 14,
  height = width,
  as: Component,
  className,
  ...props
}: IconProps) {
  return (
    <Component width={width} height={height} className={className} {...props} />
  )
}
