import { atom, type Atom } from "jotai"

const inFlightPromises = new WeakMap<Atom<unknown>, Promise<unknown>>()
/**
 * A simple helper function to create a cached atom.
 * Be aware that this should be used in an atomFamily as it will cache the value for all instances of the atom.
 */
export function withCache<T>(anAtom: Atom<Promise<T>>) {
  let cache: T | null = null

  const cachedAtom = atom(async (get) => {
    if (cache === null) {
      if (!inFlightPromises.has(anAtom)) {
        inFlightPromises.set(anAtom, get(anAtom))
      }

      cache = (await inFlightPromises.get(anAtom)) as T
    }

    return cache
  })

  cachedAtom.debugPrivate = true

  return cachedAtom
}
