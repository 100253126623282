import {
  type CustomChart,
  type CustomChartSerie,
} from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { Box, Row } from "@tokenterminal/ui/Box"
import { ChartContext } from "@tokenterminal/ui/Chart/ChartContext"
import { Divider } from "@tokenterminal/ui/Divider"
import { Stack } from "@tokenterminal/ui/Stack"
import { Text, TextWithCapsize } from "@tokenterminal/ui/Text/Text"
import { dequal } from "dequal"
import { atom, useAtomValue, type Atom } from "jotai"
import { splitAtom, unwrap } from "jotai/utils"
import { Fragment, memo, Suspense, useContext, useMemo } from "react"
import { Button } from "../../../../ui/button/Button"
import { MenuButton, MenuItem } from "../../../../ui/menu/MenuButton"
import { getChartInfoAtom } from "../../store/chart/chart-info-atom"
import { getAvailableColor } from "../../utils/colors"
import { generateId } from "../../utils/generate-id"
import { LegendItem } from "./LegendItem"
import { LegendPlaceholder } from "./Placeholder"
type LegendProps = {
  onVisibilityChange?: (id: string, isVisible: boolean) => void
  serieSettingsAtom: Atom<Array<CustomChartSerie>>
  zoomAtom: Atom<CustomChart["zoom"]>
}

const DISPLAY_LIMIT = 20

function LegendRow({
  settingsAtom,
  onVisbilityChange,
  zoomAtom,
  offsetCount,
}: {
  settingsAtom: Atom<CustomChartSerie>
  zoomAtom: Atom<Promisable<CustomChart["zoom"]>>
  onVisbilityChange?: (serieId: string, visibility: boolean) => void
  offsetCount: number
}) {
  const { chartApi } = useContext(ChartContext)
  const settings = useAtomValue(settingsAtom)
  const zoom = useAtomValue(zoomAtom)
  const chartInfo = useAtomValue(
    getChartInfoAtom({
      chartSetting: settings,
      interval: zoom,
    })
  )

  // TODO: Only check display limit when limit is properly implemented
  const legendItems = chartInfo.slice(
    0,
    Math.min(settings.limit ?? Infinity, DISPLAY_LIMIT)
  )
  const moreItems = chartInfo.slice(DISPLAY_LIMIT, settings.limit ?? Infinity)

  return (
    <Stack gap="3x">
      <TextWithCapsize variant="secondary">{settings.title}</TextWithCapsize>
      <Row gap="4x" start flexWrap="wrap">
        {legendItems.map((info, idx) => {
          const index = offsetCount + idx
          const currentColor: string =
            settings.colors?.[idx] ?? getAvailableColor(index)
          const id = generateId(settings.id, info.id)
          const label = info.name

          const isVisible = settings.visible
            ? (settings.visible?.includes?.(id) ?? true)
            : true

          return (
            <LegendItem
              key={id}
              name={label}
              color={currentColor}
              serieType={settings.chart_type}
              isActive={isVisible}
              onMouseOver={() => {
                const currentSerie = chartApi?.get(
                  `serie-${id}`
                ) as Highcharts.Series | null

                // if serie is not visible, we don't trigger this action
                if (!currentSerie?.visible) {
                  return
                }

                chartInfo.forEach((info) => {
                  const serie = chartApi?.get(
                    `serie-${info.id}`
                  ) as Highcharts.Series | null
                  if (serie && info.id !== id) {
                    serie.setState("inactive", true)
                  }
                })
              }}
              onMouseOut={() => {
                chartInfo.forEach((info) => {
                  const serie = chartApi?.get(
                    `serie-${info.id}`
                  ) as Highcharts.Series | null
                  if (serie && info.id !== id) {
                    serie.setState(undefined, true)
                  }
                })
              }}
              onClick={() => {
                if (chartInfo[idx]) {
                  onVisbilityChange?.(id, !isVisible)

                  if (!onVisbilityChange) {
                    const serie = chartApi?.get(
                      `serie-${chartInfo[idx].id}`
                    ) as Highcharts.Series | null
                    if (serie) {
                      serie.setVisible(!isVisible, true)
                    }
                  }
                }
              }}
            />
          )
        })}
        {moreItems.length > 0 && (
          <MenuButton
            trigger={
              <Button size="xsmall" variant="ghost">
                <Text size="12">+ {moreItems.length} more</Text>
              </Button>
            }
          >
            {moreItems.map((info, idx) => {
              const index = offsetCount + idx + DISPLAY_LIMIT
              const currentColor: string =
                settings.colors?.[index] ?? getAvailableColor(index)
              const id = generateId(settings.id, info.id)
              const label = info.name

              const isVisible = settings.visible
                ? (settings.visible?.includes?.(id) ?? true)
                : true

              return (
                <MenuItem
                  key={id}
                  onHoverStart={() => {
                    const currentSerie = chartApi?.get(
                      `serie-${id}`
                    ) as Highcharts.Series | null

                    // if serie is not visible, we don't trigger this action
                    if (!currentSerie?.visible) {
                      return
                    }

                    chartInfo.forEach((info) => {
                      const serie = chartApi?.get(
                        `serie-${info.id}`
                      ) as Highcharts.Series | null
                      if (serie && info.id !== id) {
                        serie.setState("inactive", true)
                      }
                    })
                  }}
                  onHoverEnd={() => {
                    chartInfo.forEach((info) => {
                      const serie = chartApi?.get(
                        `serie-${info.id}`
                      ) as Highcharts.Series | null
                      if (serie && info.id !== id) {
                        serie.setState(undefined, true)
                      }
                    })
                  }}
                  onAction={() => {
                    if (chartInfo[idx]) {
                      onVisbilityChange?.(id, !isVisible)

                      if (!onVisbilityChange) {
                        const serie = chartApi?.get(
                          `serie-${chartInfo[idx].id}`
                        ) as Highcharts.Series | null
                        if (serie) {
                          serie.setVisible(!isVisible, true)
                        }
                      }
                    }
                  }}
                >
                  <Box>
                    <LegendItem
                      name={label}
                      color={currentColor}
                      serieType={settings.chart_type}
                      isActive={isVisible}
                    />
                  </Box>
                </MenuItem>
              )
            })}
          </MenuButton>
        )}
      </Row>
    </Stack>
  )
}

function Innerlegend({
  serieSettingsAtomAtoms,
  onVisibilityChange,
  zoomAtom,
  offsetCounts,
}: {
  serieSettingsAtomAtoms: Array<Atom<CustomChartSerie>>
  offsetCounts: number[]
} & Omit<LegendProps, "serieSettingsAtom">) {
  return (
    <Row start gap="6x" alignItems="flexStart">
      {serieSettingsAtomAtoms.map((chartSettingAtom, index) => {
        return (
          <Fragment key={index}>
            {index > 0 ? <Divider vertical variant="secondary" /> : null}
            <LegendRow
              settingsAtom={chartSettingAtom}
              onVisbilityChange={onVisibilityChange}
              zoomAtom={zoomAtom}
              offsetCount={offsetCounts?.[index] ?? 0}
            />
          </Fragment>
        )
      })}
    </Row>
  )
}

export const Legend = memo(
  function Legend({ serieSettingsAtom, zoomAtom, ...props }: LegendProps) {
    const serieSettingsAtomAtoms = useAtomValue(
      useMemo(() => {
        return splitAtom(serieSettingsAtom)
      }, [serieSettingsAtom])
    )

    const zoom = useAtomValue(zoomAtom)

    const offsetCounts = useAtomValue(
      useMemo(() => {
        const offsetCountAtom = atom(async (get) => {
          const series = await get(serieSettingsAtom)

          let i = 0
          let sum = 0
          const offsets = []
          for (const serie of series.values()) {
            offsets[i++] = sum
            sum += (
              await get(
                getChartInfoAtom({
                  chartSetting: serie,
                  interval: zoom,
                })
              )
            ).slice(0, serie.limit ?? Infinity).length
          }

          return offsets
        })

        return unwrap(offsetCountAtom, (prev) => prev || [])
      }, [serieSettingsAtom, zoom])
    )

    return (
      <Suspense fallback={<LegendPlaceholder />}>
        <Innerlegend
          serieSettingsAtomAtoms={serieSettingsAtomAtoms}
          zoomAtom={zoomAtom}
          offsetCounts={offsetCounts}
          {...props}
        />
      </Suspense>
    )
  },
  (a, b) => dequal(a, b)
)
