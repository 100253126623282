import { type Interval } from "@tokenterminal/tt-analytics-api-types/dist/api/zoom"
import { dequal } from "dequal"
import { atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { fetchAtomWithRefresh } from "../../../../api/store/fetch-atom"
import { unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
import { projectsAtom } from "./meta-atoms"

type ChartDataArgs = {
  metric: string
  projectSlugs?: Array<string>
  interval: Interval
  limit?: number
}

function getFetchUrl({ metric, projectSlugs, interval, limit }: ChartDataArgs) {
  const search = new URLSearchParams([
    ["project_slugs", Array.from(new Set(projectSlugs)).join(",")],
    ["interval", interval],
  ])

  if (limit) {
    search.append("limit", String(Math.min(limit, 30)))
  }

  return `/v3/internal/charts/metrics/${metric}?${search}` as "/v3/internal/charts/metrics/:slug"
}

export const getMetricDataFromProjectsAtom = atomFamily(
  (args: ChartDataArgs) => {
    return atom(async (get) => {
      if (!args.projectSlugs?.length || !args.metric) {
        return {
          ok: {
            data: [],
          },
          err: null,
        }
      }

      const payloadAtom = fetchAtomWithRefresh(getFetchUrl(args))
      const [payload, projects] = await Promise.all([
        get(payloadAtom),
        Promise.resolve(get(projectsAtom)).then((data) =>
          toDictionary(unwrapWithData(data), "id")
        ),
      ])

      if (payload.ok) {
        return {
          ok: {
            data: payload.ok.data.map((row) => {
              return {
                ...row,
                api_id: projects.get(row.api_id)?.data_id ?? row.api_id,
              }
            }),
          },
          err: null,
        }
      }

      return payload
    })
  },
  dequal
)
