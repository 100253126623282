import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  type ReactNode,
  useRef,
} from "react"
import {
  Button as AriaButton,
  type ButtonProps as ButtonAriaProps,
} from "react-aria-components"
import { twMerge } from "tailwind-merge"
import { Icon } from "../icon"
import { type IconProps } from "../icon/Icon"

type Color = "neutral" | "success" | "warning" | "danger"
export type Size = "xsmall" | "small" | "medium" | "large"
type Variant = "default" | "solid" | "ghost" | "muted"

export type ButtonProps = Omit<
  ButtonAriaProps,
  "children" | "isPending" | "className"
> & {
  color?: Color
  className?: string
  /** The handler to call when the button is clicked. Should be used rather than onClick. */
  onPress?: ButtonAriaProps["onPress"]
  /** Whether the button is loading. */
  loading?: ButtonAriaProps["isPending"]
  size?: Size
  /** The emphasis of the button. `solid` is the most prominent, `ghost` is
   * the least. `muted` should only be used for active button state patterns
   * like tabs. */
  variant?: Variant
  /** The content of the button. Can be a string or an icon. */
  children: ReactNode
}

export type BaseButtonProps = ButtonAriaProps
export const BaseButton = AriaButton

const iconSizeLookup = {
  xsmall: "12",
  small: "14",
  medium: "14",
  large: "14",
} as const

/**
 * Trigger actions or events when clicked.
 *
 * ```tsx
 * import { Star } from "@phosphor-icons/react"
 * import { Button } from "@tokenterminal/ui/button/Button"
 *
 * <Button onPress={...}>Click me</Button>
 * <Button color="success" onPress={...}>
 *   <Icon as={Star} />
 *   Click me
 * </Button>
 * ```
 */

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const {
      children,
      className,
      color = "neutral",
      isDisabled,
      loading,
      size = "medium",
      variant = "default",
      ...rest
    } = props
    const fallbackRef = useRef<HTMLButtonElement>(null)
    const normalizedRef = ref || fallbackRef

    return (
      <AriaButton
        ref={normalizedRef}
        isDisabled={isDisabled ?? loading}
        isPending={loading}
        data-size={size}
        data-variant={variant}
        data-color={color}
        className={twMerge("tt-button", className)}
        {...rest}
      >
        {Children.map(children, (child) => {
          if (typeof child === "string") {
            return child
          } else if (isValidElement(child) && child.type === Icon) {
            return cloneElement(child, {
              width: iconSizeLookup[size],
            } as Partial<IconProps>)
          }

          return child
        })}
      </AriaButton>
    )
  }
)
