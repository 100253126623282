import { type GETRequests } from "@tokenterminal/tt-analytics-api-types/dist/api-routes"

import { type SchemaDefinition } from "@tokenterminal/tt-types/client"
import { dequal } from "dequal"
import { type WritableAtom, atom, type ExtractAtomValue } from "jotai"
import { atomFamily } from "jotai/utils"
import { type Result } from "../../utils/jotai/unwrap"
import { withSuspendAtom } from "../../utils/jotai/withSuspendAtom"
import { type EndpointsGET } from "../api-get"
import { type FetchError } from "../fetch-error"
import { fetchApiAtom, fetchApiFamilyAtom } from "./fetch-api-atom"
import {
  fetchApiClientAtom,
  type Options,
  type Endpoint,
  fetchApiClientFamilyAtom,
} from "./fetch-tt-client-atom"

const fetchAtomWithRefreshAtom = atomFamily(
  ({
    endpoint,
    options,
  }: {
    endpoint: EndpointsGET | Endpoint
    options?: Options<Endpoint>
  }) => {
    let baseAtom:
      | ReturnType<typeof fetchApiAtom<EndpointsGET>>
      | ReturnType<typeof fetchApiClientAtom>

    const isOldEndpoint = endpoint.startsWith("/")
    if (isOldEndpoint) {
      baseAtom = fetchApiAtom(endpoint as EndpointsGET)
    } else {
      baseAtom = fetchApiClientAtom(endpoint as Endpoint, options)
    }
    const suspendableFetchAtom = withSuspendAtom<
      ExtractAtomValue<typeof baseAtom>
    >((get) =>
      // @ts-ignore -- fix types later
      get(baseAtom)
    )

    const fetchAtomWithRefreshAtom = atom(
      (get) => get(suspendableFetchAtom),
      (get, set) => {
        // make sure to remove the old atom from the cache
        if (isOldEndpoint) {
          fetchApiFamilyAtom.remove(endpoint as EndpointsGET)
          baseAtom = fetchApiAtom(endpoint as EndpointsGET)
        } else {
          fetchApiClientFamilyAtom.remove({ endpoint, options } as {
            endpoint: Endpoint
            options: Options<Endpoint>
          })
          baseAtom = fetchApiClientAtom(endpoint as Endpoint, options)
        }

        set(suspendableFetchAtom)
      }
    )
    fetchAtomWithRefreshAtom.debugLabel = `fetchAtomWithRefresh(${endpoint})`

    return fetchAtomWithRefreshAtom
  },
  dequal
)

type TTClientResponse<T extends Endpoint> = SchemaDefinition[T]["response"]
type ApiResponse<T extends EndpointsGET> = GETRequests[T][0]

type WritableTTClientAtom<T extends Endpoint> = WritableAtom<
  PromiseLike<Result<TTClientResponse<T>, FetchError>>,
  [],
  void
>
type WritableApiAtom<T extends EndpointsGET> = WritableAtom<
  PromiseLike<Result<ApiResponse<T>, FetchError>>,
  [],
  void
>

export function fetchAtomWithRefresh<T extends Endpoint>(
  endpoint: T,
  options: SchemaDefinition[T]["options"]
): WritableTTClientAtom<T>
export function fetchAtomWithRefresh<T extends EndpointsGET>(
  endpoint: T
): WritableApiAtom<T>
export function fetchAtomWithRefresh<T extends EndpointsGET | Endpoint>(
  endpoint: T,
  options?: T extends EndpointsGET
    ? never
    : SchemaDefinition[Endpoint]["options"]
): T extends EndpointsGET
  ? WritableApiAtom<EndpointsGET>
  : WritableTTClientAtom<Endpoint> {
  return fetchAtomWithRefreshAtom({
    endpoint,
    options,
  }) as unknown as T extends EndpointsGET
    ? WritableApiAtom<EndpointsGET>
    : WritableTTClientAtom<Endpoint>
}
