import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type Interval } from "@tokenterminal/tt-analytics-api-types/dist/api/zoom"
import {
  type ChartProps as UIChartProps,
  Chart as UIChart,
  type GranularityType,
  type ChartSerie,
} from "@tokenterminal/ui/Chart/Chart"
import { BarSkeleton } from "@tokenterminal/ui/Chart/ChartLoadingIndicator"
import { type Atom, useAtomValue } from "jotai"
import { Suspense, useMemo } from "react"
import { getSeriesFromChartSettingsAtom } from "../../store/chart/series-atom"
import { getSeriesDataFromChartSettingsAtom } from "../../store/chart/series-data-atom"
import { getTooltipOptionsFromChartSettingsAtomFamily } from "../../store/chart/tooltip-options-atom"
import { getYAxisOptionsFromChartSettingsAtomFamily } from "../../store/chart/yaxis-options-atom"

import {
  getColorPaletteColorVariableById,
  isColorPaletteColorId,
} from "../../utils/colors"
import { convertApiToZoom } from "../../utils/convert-zoom-to-api"

type InnerBaseProps = {
  chartSerieSettingsAtom: Atom<Array<CustomChartSerie>>
  zoomAtom: Atom<Interval>
  granularityAtom: Atom<GranularityType>
  showCurrentPeriodAtom: Atom<boolean>
  isVisible?: boolean
}

type BaseChartProps = Pick<
  InnerBaseProps,
  | "chartSerieSettingsAtom"
  | "zoomAtom"
  | "granularityAtom"
  | "showCurrentPeriodAtom"
  | "isVisible"
>

export function InnerBaseChart({
  chartSerieSettingsAtom,
  zoomAtom,
  granularityAtom,
  showCurrentPeriodAtom,
  isVisible,
}: InnerBaseProps) {
  const chartSerieSettings = useAtomValue(chartSerieSettingsAtom)
  const zoom = useAtomValue(zoomAtom)
  const granularity = useAtomValue(granularityAtom)
  const showCurrentPeriod = useAtomValue(showCurrentPeriodAtom)

  const series = useAtomValue(
    getSeriesFromChartSettingsAtom({
      chartSettings: chartSerieSettings,
      interval: zoom,
    })
  )
  const seriesData = useAtomValue(
    getSeriesDataFromChartSettingsAtom({
      chartSettings: chartSerieSettings,
      zoom,
    })
  )
  const yAxisOptions: UIChartProps["yAxisOptions"] = useAtomValue(
    getYAxisOptionsFromChartSettingsAtomFamily(chartSerieSettings)
  )
  const tooltipOptions = useAtomValue(
    getTooltipOptionsFromChartSettingsAtomFamily(chartSerieSettings)
  )

  const seriesWithParsedColors = useMemo(() => {
    return series.map((serie) => {
      const result = JSON.parse(JSON.stringify(serie)) as ChartSerie
      if (isColorPaletteColorId(serie.color || "")) {
        // Can cast the color as a string since we know it's a color palette color ID
        result.color = getColorPaletteColorVariableById(serie.color as string)
      }

      return result
    })
  }, [series])

  return isVisible ? (
    <UIChart
      series={seriesWithParsedColors}
      seriesData={seriesData}
      zoom={convertApiToZoom(zoom)}
      granularity={granularity}
      yAxisOptions={yAxisOptions}
      showNavigator={false}
      isTimeBased
      tooltipOptions={tooltipOptions}
      legend={false}
      showCurrentPeriod={showCurrentPeriod}
    />
  ) : null
}

export function BaseChart(props: BaseChartProps) {
  return (
    <Suspense
      fallback={
        <BarSkeleton
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      }
    >
      <InnerBaseChart {...props} />
    </Suspense>
  )
}
