import { type CompositionChartSeriesPayload } from "@tokenterminal/tt-analytics-api-types/dist/api/charts"
import { type Interval } from "@tokenterminal/tt-analytics-api-types/dist/api/zoom"
import { dequal } from "dequal"
import { atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { type FetchError } from "../../../../api"
import { fetchAtomWithRefresh } from "../../../../api/store/fetch-atom"
import { type Result } from "../../../../utils/jotai/unwrap"

export type CompositionChartDataArgs = {
  composeBy: "chain"
  metric: string
  projects: Array<string>
  interval: Interval
}

function getFetchUrl({ metric, projects, interval }: CompositionChartDataArgs) {
  const searchArgs = new URLSearchParams([
    [`data_ids`, projects.join(",")],
    ["composition_by", "chain_id"],
    ["interval", interval],
  ])

  return `/v3/internal/charts/metrics/${metric}/composition?${searchArgs.toString()}` as `/v3/internal/charts/metrics/:slug/composition`
}

export const getMetricCompositionChartDataByMetricAndChainAtom = atomFamily(
  (args: CompositionChartDataArgs) => {
    return atom(async (get) => {
      if (!args.projects?.length || !args.metric) {
        return {
          ok: {
            data: [],
          },
          err: null,
        }
      }

      const payload: Result<CompositionChartSeriesPayload, FetchError> =
        await get(fetchAtomWithRefresh(getFetchUrl(args)))

      if (payload.ok) {
        return {
          ok: {
            data: payload.ok.data,
          },
          err: null,
        }
      }

      return payload
    })
  },
  dequal
)
