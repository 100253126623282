import {
  type Filter,
  type CustomChartSerie,
  type CustomChart,
} from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type ProjectMeta } from "@tokenterminal/tt-analytics-api-types/dist/api/projects"
import { dequal } from "dequal"
import { atom, type Atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { fetchAtom } from "../../../../api"
import {
  getProjectsAtom,
  getChainsAtom,
  getProjectsByBlockchainAtom,
  getProjectsByMarketSectorAtom,
  getProjectsByMetricAtom,
} from "../../../../data/store/projects-atom"
import { unwrapWithData } from "../../../../utils/jotai/unwrap"
import { withCache } from "../../../../utils/jotai/withCache"
import { toDictionary } from "../../../../utils/toDictionary"
import { marketSectorsAtom } from "./meta-atoms"
import { getMetricCompositionChartDataByMetricAndChainAtom } from "./metric-composition-data-atom"
import { getAggregatedChartDataByMetricAndBlockchainAtom } from "./metrics-blockchain-data-atom"

function getFiltersAsRecord(
  filters: Array<Filter>
): Record<NonNullable<Filter["type"]>, Filter> {
  return filters.reduce(
    (acc, filter) => {
      if (filter.type) {
        acc[filter.type] = filter
      }

      return acc
    },
    {} as Record<NonNullable<Filter["type"]>, Filter>
  )
}

function getChartInfoSortOrderAtom({
  filters,
  groupBy,
  metric,
  interval,
}: Pick<CustomChartSerie, "filters" | "groupBy" | "metric"> & {
  interval: CustomChart["zoom"]
}) {
  const chartInfoSortOrderAtom = atom(async (get) => {
    if (filters.length === 1 && filters[0]!.type === "market_sector") {
      const projects = await Promise.all(
        filters[0]!.values.map((sector) =>
          get(getProjectsByMarketSectorAtom(sector))
        )
      )
      const projectsList = Array.from(
        new Set(projects.flat().map((project) => project.data_id))
      )
      filters = [
        {
          type: "project",
          values: projectsList,
          comparator: "in",
        },
      ]
    }

    const getTimeseriesAtom = fetchAtom("getTimeseries", {
      body: {
        groupBy: groupBy === "project" ? "projects" : (groupBy ?? "none"),
        // @ts-ignore - bad zod inference
        interval,
        metric_ids: [metric.replace(/-/g, "_")],
        chain_ids:
          filters.find((filter) => filter.type === "blockchain")?.values ??
          undefined,
        data_ids:
          filters.find((filter) => filter.type === "project")?.values ??
          undefined,
      },
    })

    const res = await get(getTimeseriesAtom)

    if (res.err) {
      throw res.err
    }

    return res.ok.sorted_data_ids
  })

  return chartInfoSortOrderAtom
}

type getChartInfoByMarketSectorsProps = Pick<
  CustomChartSerie,
  "filters" | "groupBy" | "metric"
>
function getChartInfoByMarketSectorsAtom({
  filters,
  groupBy,
  metric,
}: getChartInfoByMarketSectorsProps): Atom<
  Promise<Array<{ id: string; name: string }>>
> {
  const chartInfoByMarketSectorsAtom = atom(async (get) => {
    const filtersAsRecord = getFiltersAsRecord(filters) as ReturnType<
      typeof getFiltersAsRecord
    > & { market_sector: Filter }
    if (!filtersAsRecord.market_sector) {
      throw new Error("Market sector filter is required")
    }

    // group by chain = Market sector
    if (groupBy === "chain") {
      const marketSectors = unwrapWithData(await get(marketSectorsAtom))

      return marketSectors
        .filter((sector) => {
          return filtersAsRecord.market_sector.values.includes(sector.id)
        })
        .map((marketSector) => {
          return {
            id: marketSector.id,
            name: marketSector.name,
          }
        })
    }

    // Handle unique grouping by projects
    const projectsMap = new Map<
      string,
      {
        id: string
        name: string
      }
    >()

    const projectsByMetric = await get(getProjectsByMetricAtom(metric))
    const projectResults = await Promise.all(
      filtersAsRecord.market_sector.values.map((marketSector) =>
        get(getProjectsByMarketSectorAtom(marketSector))
      )
    )
    projectResults.flat().forEach((project) => {
      if (projectsByMetric.includes(project)) {
        projectsMap.set(project.data_id, {
          id: project.data_id,
          name: project.name,
        })
      }
    })

    return Array.from(projectsMap.values())
  })

  return chartInfoByMarketSectorsAtom
}

type getChartInfoByBlockchainProps = Pick<
  CustomChartSerie,
  "filters" | "groupBy" | "metric"
>
function getChartInfoByBlockchainAtom({
  filters,
  groupBy,
  metric,
}: getChartInfoByBlockchainProps): Atom<
  Promise<Array<{ id: string; name: string }>>
> {
  const chartInfoByBlockchainAtom = atom(async (get) => {
    const filtersAsRecord = getFiltersAsRecord(filters) as ReturnType<
      typeof getFiltersAsRecord
    > & { blockchain: Filter }
    if (!filtersAsRecord.blockchain) {
      throw new Error("Blockchain filter is required")
    }

    const projects = unwrapWithData(await get(getProjectsAtom))

    if (groupBy === "chain") {
      // We're querying chart data to get all names of projects and chains
      // TODO have a separate endpoint for this
      const projectAndChainData = unwrapWithData(
        await get(
          getAggregatedChartDataByMetricAndBlockchainAtom({
            // TODO move to metric id
            metric: metric.replace(/-/g, "_"),
            chainIds: filtersAsRecord.blockchain.values,
            projectIds: filtersAsRecord.project?.values ?? [],
            interval: "max",
          })
        )
      )

      const projectDictionary = toDictionary(projects, "data_id")
      const uniqueProjectsMap = new Map<string, { id: string; name: string }>()
      projectAndChainData.map((item) => {
        if (
          item.data_id &&
          item.chain_id &&
          projectDictionary.has(item.data_id)
        ) {
          uniqueProjectsMap.set(`${item.chain_id}`, {
            id: `${item.chain_id}`,
            name: `${projectDictionary.get(item.chain_id)?.name ?? item.chain_id}`,
          })
        }
      })

      return Array.from(uniqueProjectsMap.values())
    } else if (groupBy === "project+chain") {
      let projectsToFetch = filtersAsRecord.project?.values ?? []
      if (filtersAsRecord.market_sector && projectsToFetch.length === 0) {
        const projectResults = await Promise.all(
          filtersAsRecord.market_sector.values.map((marketSector) =>
            get(getProjectsByMarketSectorAtom(marketSector))
          )
        )
        projectsToFetch = projectResults
          .flat()
          .map((project) => project.data_id)
      }

      // We're querying chart data to get all names of projects and chains
      // TODO have a separate endpoint for this
      const projectAndChainData = unwrapWithData(
        await get(
          getAggregatedChartDataByMetricAndBlockchainAtom({
            // TODO move to metric id
            metric: metric.replace(/-/g, "_"),
            chainIds: filtersAsRecord.blockchain.values,
            projectIds: projectsToFetch,
            interval: "max",
          })
        )
      )
      const projectDictionary = toDictionary(projects, "data_id")

      const formatKey = (item: (typeof projectAndChainData)[number]) => {
        return `${item.chain_id}-${item.data_id}`
      }

      return Array.from(
        new Set(projectAndChainData.map((item) => formatKey(item)))
      )
        .filter((key) => {
          const [chain, project] = key.split("-")
          return (
            projectDictionary.has(project!) && projectDictionary.has(chain!)
          )
        })
        .map((key) => {
          const [chain, project] = key.split("-")
          return {
            id: key,
            name: `${projectDictionary.get(project!)?.name ?? project} (${projectDictionary.get(chain!)?.name ?? chain})`,
          }
        })
    }

    // Handle unique grouping by projects
    const projectsMap = new Map<
      string,
      {
        id: string
        name: string
      }
    >()

    const availableProjectsByMarketSector = new Set<string>()
    const marketSectorFilter = filtersAsRecord.market_sector?.values ?? []
    if (marketSectorFilter.length === 0) {
      projects.forEach((project) => {
        availableProjectsByMarketSector.add(project.data_id)
      })
    } else {
      const projectsByMarketSector = await Promise.all(
        marketSectorFilter.map((sector) =>
          get(getProjectsByMarketSectorAtom(sector))
        )
      )

      projectsByMarketSector.flat().forEach((project) => {
        availableProjectsByMarketSector.add(project.data_id)
      })
    }

    const projectsByMetric = await get(getProjectsByMetricAtom(metric))
    let projectResults: Array<ProjectMeta> = []
    if (filtersAsRecord.project) {
      const projectDictionary = toDictionary(projects, "data_id")
      projectResults = filtersAsRecord.project.values.map(
        (projectId) => projectDictionary.get(projectId)!
      )
    } else {
      projectResults = (
        await Promise.all(
          filtersAsRecord.blockchain.values.map((blockchain) =>
            get(getProjectsByBlockchainAtom(blockchain))
          )
        )
      ).flat()
    }

    projectResults.forEach((project) => {
      if (
        projectsByMetric.includes(project) &&
        availableProjectsByMarketSector.has(project.data_id)
      ) {
        projectsMap.set(project.data_id, {
          id: project.data_id,
          name: project.name,
        })
      }
    })

    return Array.from(projectsMap.values())
  })

  return chartInfoByBlockchainAtom
}

type getChartInfoByProjectsProps = Pick<
  CustomChartSerie,
  "filters" | "groupBy" | "metric"
>
function getChartInfoByProjectsAtom({
  filters,
  groupBy,
  metric,
}: getChartInfoByProjectsProps): Atom<
  Promise<Array<{ id: string; name: string }>>
> {
  const chartInfoByProjectsAtom = atom(async (get) => {
    const filtersAsRecord = getFiltersAsRecord(filters) as ReturnType<
      typeof getFiltersAsRecord
    > & { project: Filter }
    if (!filtersAsRecord.project) {
      throw new Error("Project filter is required")
    }

    const projects = unwrapWithData(await get(getProjectsAtom))
    const chains = unwrapWithData(await get(getChainsAtom))
    const projectDictionary = toDictionary(projects, "data_id")
    const chainsDirectory = toDictionary(chains, "chain_id")
    const projectsToFetch = filtersAsRecord.project.values

    if (groupBy === "chain") {
      const chainsCompositionPayload = await get(
        getMetricCompositionChartDataByMetricAndChainAtom({
          composeBy: "chain",
          metric: metric,
          projects: projectsToFetch,
          interval: "24h",
        })
      )
      const chainsCompositionData = unwrapWithData(chainsCompositionPayload)

      return (
        chainsCompositionData
          .map((item) => `${item.data_id}-${item.composition_value}`)
          // make sure project is known
          .filter((key) => {
            const [project, chain] = key.split("-")

            return (
              projectDictionary.has(project!) && chainsDirectory.get(chain!)
            )
          })
          .map((key) => {
            const [project, chain] = key.split("-")

            return {
              id: key,
              name: `${projectDictionary.get(project!)?.name ?? project} (${chainsDirectory.get(chain!)?.name ?? chain})`,
            }
          })
      )
    }

    const projectsByMetric = await get(
      getProjectsByMetricAtom(metric.replace(/-/g, "_"))
    )
    const projectsByMetricDictionary = toDictionary(projectsByMetric, "data_id")

    return (
      projectsToFetch
        // make sure project is known
        .filter((projectId) => projectsByMetricDictionary.has(projectId))
        .map((projectId) => {
          return {
            id: projectId,
            name: `${projectDictionary.get(projectId!)?.name ?? projectId}`,
          }
        })
    )
  })

  return chartInfoByProjectsAtom
}

export function chartInfoAtomFamilyEqualFn(
  a: {
    chartSetting: getChartInfoAtomProps
    interval: CustomChart["zoom"]
  },
  b: {
    chartSetting: getChartInfoAtomProps
    interval: CustomChart["zoom"]
  }
) {
  // make sure we only check the relevant keys instead of hte whole object
  // this constant is just a typed guard to make sure we're not missing any keys
  const keys: Array<keyof getChartInfoAtomProps> = [
    "filters",
    "groupBy",
    "metric",
  ]

  return keys.every(
    (key) =>
      a.interval === b.interval &&
      dequal(a.chartSetting[key], b.chartSetting[key])
  )
}

export type getChartInfoAtomProps = getChartInfoByMarketSectorsProps &
  getChartInfoByBlockchainProps &
  getChartInfoByProjectsProps
export const getChartInfoAtom = atomFamily(function getChartInfoAtom({
  chartSetting,
  interval,
}: {
  chartSetting: getChartInfoAtomProps & { id: string }
  interval: CustomChart["zoom"]
}) {
  const chartInfoAtom = atom(async (get) => {
    if (!chartSetting.metric) {
      return [] satisfies Array<string>
    }

    if (!chartSetting.groupBy) {
      return [
        {
          id: "aggregated",
          name: "Aggregated",
        },
      ]
    }

    let groupBy = chartSetting.groupBy

    const filters = chartSetting.filters.reduce(
      (acc, filter) => {
        if (filter.type) {
          acc[filter.type] = filter
        }

        return acc
      },
      {} as Record<NonNullable<Filter["type"]>, Filter>
    )

    let chartInfo: Array<{ id: string; name: string }> = []
    if (
      chartSetting.filters.length === 1 &&
      chartSetting.filters[0]!.type === "market_sector"
    ) {
      groupBy = chartSetting.groupBy === "chain" ? "market-sector" : "project"
      chartInfo = await get(getChartInfoByMarketSectorsAtom(chartSetting))
    }

    if (filters.project) {
      chartInfo = await get(getChartInfoByProjectsAtom(chartSetting))
    }

    if (filters.blockchain) {
      chartInfo = await get(getChartInfoByBlockchainAtom(chartSetting))
    }

    const chartInfoSortOrderPromise = get(
      getChartInfoSortOrderAtom({
        filters: chartSetting.filters,
        groupBy,
        metric: chartSetting.metric,
        interval,
      })
    )

    if (chartInfo.length < 2) {
      return chartInfo
    }

    const chartInfoSortOrder = await chartInfoSortOrderPromise

    const formatKey = (item: {
      chain_id?: string | undefined
      data_id: string
      market_sector_id?: string | undefined
    }) => {
      if (groupBy === "market-sector") {
        return item?.market_sector_id ?? item
      }

      return filters.project
        ? `${item.data_id ? `${item.data_id}${item.chain_id ? "-" : ""}` : ""}${item.chain_id ?? ""}`
        : `${item.chain_id ? `${item.chain_id}${item.data_id ? "-" : ""}` : ""}${item.data_id ?? ""}`
    }

    // Create a map of sort orders for faster lookup
    const sortOrderMap = new Map(
      chartInfoSortOrder.map((item, index) => [formatKey(item), index])
    )

    if (
      filters.blockchain &&
      chartSetting.groupBy === "project" &&
      (!filters.project || !filters.market_sector)
    ) {
      // TODO: We should be using this data for the chart instead of just the sort order
      chartInfo = chartInfo.filter(
        (item) =>
          sortOrderMap.has(item.id) &&
          !filters.blockchain.values.includes(item.id)
      )
    }

    // Filter out items not in sort order map and sort remaining
    return chartInfo.sort((a, b) => {
      const indexA = sortOrderMap.get(a.id)! ?? Number.MAX_SAFE_INTEGER
      const indexB = sortOrderMap.get(b.id)! ?? Number.MAX_SAFE_INTEGER
      return indexA - indexB
    })
  })

  chartInfoAtom.debugLabel = `getChartInfoAtom(${chartSetting.id})`

  return withCache(chartInfoAtom)
}, chartInfoAtomFamilyEqualFn)
